<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <spinner v-if="isLoading" />
    <div
      class="d-flex"
    >
      <v-col class="col-lg-4 col-md-6 col-sm-10">
        <form @submit.prevent="search">
          <v-text-field
            v-model="email"
            :error="$v.email.$error"
            :error-messages="!$v.email.email ? 'Enter a valid email' : []"
            counter
            clearable
            label="Search by email"
            prepend-inner-icon="mdi-magnify"
            single-line
            maxlength="64"
            autocomplete="off"
          />
        </form>
      </v-col>
      <div class="ml-auto">
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                large
                icon
                color="blue"
                v-on="on"
                @click="refresh"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </v-col>
      </div>
    </div>
    <v-row v-if="profiles.length === 0 && !isLoading">
      <v-col
        cols="12"
      >
        <v-card
          class="mx-auto grow"
        >
          <v-card-text>
            <p class="display-1 text--primary text-center">
              No users
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-for="(profile, index) in profiles"
        :key="profile.id"
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-card
          icon="mdi-account"
          class="v-card--material-stats"
          v-bind="$attrs"
          color="primary"
          v-on="$listeners"
        >
          <template v-slot:after-heading>
            <div class="ml-auto text-right">
              <div
                class="body-3 font-weight-bold"
                v-text="formatName(profile.firstName, profile.lastName)"
              />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      :color="profile.isVerified ? 'green' : 'red' "
                      size="16"
                      class="ml-2 mr-1"
                    >
                      {{ profile.isVerified ? 'mdi-shield-check' : 'mdi-close' }}
                    </v-icon></span>
                </template>
                <span>{{ profile.isVerified ? 'verified' : 'not verified' }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      :color="profile.isActive ? 'green' : 'red'"
                      size="16"
                      class="ml-2 mr-1"
                    >
                      {{ profile.isActive ? 'mdi-account-check-outline' : 'mdi-account-off-outline' }}
                    </v-icon>
                  </span>
                </template>
                <span>{{ profile.isActive ? 'active' : 'not active' }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      color="blue"
                      size="16"
                      class="ml-2 mr-1"
                    >
                      {{ profile.role === 'user' ? 'mdi-account-outline' : 'mdi-clipboard-check' }}
                    </v-icon></span>
                </template>
                <span>{{ profile.role }}</span>
              </v-tooltip>
            </div>
          </template>

          <v-col
            cols="12"
            class="px-0"
          >
            <v-divider />
          </v-col>

          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-content>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-title
                        color="black"
                        v-text="`${humanify(profile.createdAt)}`"
                      />
                    </span>
                  </template>
                  <span>Joined {{ formatDate(profile.createdAt) }}</span>
                </v-tooltip>
              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >
                <v-dialog
                  v-model="dialog[index]"
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="indigo"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-account-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card :loading="isLoading">
                    <v-card-title
                      class="h2 font-weight-bold"
                      v-text="formatName(profile.firstName, profile.lastName)"
                    />
                    <v-spacer />
                    <v-card-title
                      class="subtitle-1 font-weight-bold"
                      v-text="profile.email"
                    />

                    <v-divider class="mt-4" />

                    <div class="mt-5">
                      <v-row
                        class="my-2"
                        align="center"
                        justify="center"
                      >
                        <v-btn
                          :disabled="profile.role === 'administrator'"
                          :color="profile.role === 'user' ? 'indigo' : 'red'"
                          class="ma-1"
                          @click="toggleIsOrganiser(profile.id, profile.role)"
                        >
                          {{ profile.role === 'user' ? 'Promote' : 'Demote' }}
                        </v-btn>

                        <v-btn
                          disabled
                          color="indigo"
                          outlined
                          @click="toggleIsActive()"
                        >
                          {{ profile.isActive ? 'Disable' : 'Enable' }}
                        </v-btn>
                      </v-row>
                    </div>

                    <v-card-actions>
                      <v-spacer />

                      <v-btn
                        color="red darken-1"
                        text
                        @click="dialog = []"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
    <template v-if="!isLoading">
      <div class="text-center">
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <v-pagination
              v-model="page"
              :disabled="profiles.length === 0"
              :length="pageCount"
              :total-visible="6"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-card-text
              v-if="total > 0"
              class="subtitle-1"
            >
              {{ `${page === 1 ? 1 : (page-1) * perPage + 1} - ${(page * perPage) > total ? total:(page * perPage)} of ${total}` }}
            </v-card-text>
            <v-card-text
              v-else
              class="subtitle-1"
            >
              0 of 0
            </v-card-text>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="perPage"
              type="number"
              :disabled="profiles.length === 0"
              :items="perPageOptions"
              label="Total visible"
              @change="page = 1"
            />
          </v-col>
        </v-row>
      </div>
    </template>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { email, maxLength } from 'vuelidate/lib/validators'
  import moment from 'moment'
  import spinner from '@/components/layouts/Spinner'
  import { redirectMixin } from '@/mixins/redirectMixin'

  export default {
    name: 'DashboardDashboard',
    components: {
      spinner,
    },
    mixins: [redirectMixin],
    data () {
      return {
        page: 1,
        perPage: 20,
        pageCount: 1,
        dialog: [],
        total: 0,
        perPageOptions: [20, 40, 100, 200],
        email: '',
      }
    },
    validations: {
      email: { email, maxLength: maxLength(64) },
    },
    computed: {
      ...mapState({
        profiles: state => state.profileData.profiles,
        updatedProfile: state => state.profileData.updatedProfile,
        metadata: state => state.profileData.metadata,
        isLoading: state => state.profileData.isLoading,
        profilesError: state => state.profileData.profilesError,
        currentUser: state => state.userData.currentUser,
      }),
    },
    watch: {
      metadata: function () {
        if (this.metadata && this.metadata.pageCount) {
          this.pageCount = this.metadata.pageCount
          this.total = this.metadata.total
        }
      },
      page: function () {
        const { token } = this.currentUser
        this.getProfiles({ token, page: this.page, perPage: this.perPage })
      },
      perPage: function () {
        const { token } = this.currentUser
        this.getProfiles({ token, page: this.page, perPage: this.perPage })
      },
      updatedProfile: function () {
        if (this.updatedProfile) {
          this.refresh()
        }
      },
      profilesError: function () {
        if (this.profilesError && this.profilesError.statusCode === 401) {
          const { email } = this.currentUser

          this.redirectToSignin(email, 'Kindly log in again, your session may have expired or you may have logged out from another device')
        }
      },
    },
    created () {
      this.refresh()
    },
    methods: {
      ...mapActions('profileData', ['getProfiles', 'updateUserRole', 'searchProfiles']),
      formatName (firstName, lastName) {
        return `${firstName} ${lastName}`
      },
      humanify (date) {
        return moment(date).fromNow()
      },
      formatDate (date) {
        return moment(date).format('MMM DD, YYYY')
      },
      toggleIsActive (profileId, profileRole) {},
      toggleIsOrganiser (profileId, profileRole) {
        const { token } = this.currentUser
        const role = profileRole === 'user' ? 'organiser' : 'user'

        this.updateUserRole({
          id: profileId,
          token,
          user: {
            role,
          },
        })
      },
      refresh () {
        const { token } = this.currentUser
        this.getProfiles({ token, page: this.page, perPage: this.perPage })
      },
      search () {
        if (this.email) {
          this.$v.$touch()
          if (!this.$v.$invalid) {
            const { token } = this.currentUser
            this.searchProfiles({ token, page: this.page, perPage: this.perPage, email: this.email })
          }
        } else {
          this.refresh()
        }
      },
    },
  }
</script>
