<template>
  <div class="spinner">
    <v-progress-circular
      :indeterminate="indeterminate"
      :rotate="rotate"
      :size="size"
      :width="width"
      color="primary"
    />
  </div>
</template>

<script>
  export default {
    name: 'Spinner',
    data () {
      return {
        indeterminate: true,
        rotate: 180,
        size: 32,
        value: 0,
        width: 4,
      }
    },
  }
</script>

<style scoped>
/* Absolute Center Spinner */
.spinner {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 100%;
  overflow: visible;
  text-align: center;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/* Transparent Overlay */
.spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
